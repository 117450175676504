"use client"
import { useModal } from "@/hooks/useModal"
import { useMemo } from "react"
import { useGlobalContext } from "@/hooks/useGlobalContext"
import SignInModalPortal from "./SignInModalPortal"
import { ClientSafeProvider, signIn } from "next-auth/react"
import { SecondaryButton } from "./Button"
import { usePathname } from "next/navigation"
import { IconX } from "@tabler/icons-react"

export const SignInModal = ({ providers }: any) => {
  const { closeSignInModal, openSignInModal } = useModal()
  const {
    state: { signInModalIsOpen },
  } = useGlobalContext()

  const pathname = usePathname()

  useMemo(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (target.id === "innerSignInModal") {
        closeSignInModal()
      }
    }
    if (signInModalIsOpen) {
      if (typeof window !== "undefined") {
        document.body.style.overflowY = "hidden"
        window.addEventListener("click", handleOutsideClick)
      }
    } else {
      if (typeof window !== "undefined") {
        document.body.style.overflowY = "auto"
      }
    }
    return () => {
      window.removeEventListener("click", handleOutsideClick)
    }
  }, [signInModalIsOpen])

  return (
    <SignInModalPortal>
      <div
        id="innerSignInModal"
        className="fixed top-0 left-0 w-full h-full min-h-screen bg-white bg-opacity-90 flex items-center justify-center px-4 md:px-0 z-[1001]"
      >
        <div className="w-full flex flex-col items-center justify-center max-w-md bg-white border-2 border-black rounded-2xl shadow-[4px_4px_0_0_#000] p-4 relative">
          <button
            aria-label="Close"
            onClick={closeSignInModal}
            className="absolute top-4 right-4 text-black"
          >
            <IconX />
          </button>

          <h2 className="text-2xl font-bold text-black mb-4">Sign In</h2>
          {/* <Text className="mb-4 text-center">{description}</Text> */}
          {/* <div className="w-full max-w-sm flex flex-col justify-center items-center gap-2">
            <button
              aria-label="Confirm"
              onClick={onConfirm}
              className="py-3 rounded-xl bg-red-500 hover:bg-red-600 border-2 border-white transition text-white w-full"
            >
              {confirmText}
            </button>
            <button
              aria-label="Cancel"
              onClick={closeModal}
              className="py-3 rounded-xl transition text-zinc-400 hover:text-white w-full"
            >
              {cancelText}
            </button>
          </div> */}

          {providers &&
            Object.values(providers).map((provider: any) => {
              const safeProvider = provider as ClientSafeProvider
              return (
                <SecondaryButton
                  label={`Sign in with ${safeProvider.name}`}
                  key={safeProvider.name}
                  handleClick={() =>
                    signIn(safeProvider.id, { callbackUrl: "/dashboard" })
                  }
                  className={`w-full relative flex items-center justify-center`}
                >
                  <span className="block px-2">
                    <img
                      src={`/${safeProvider.name.toLowerCase()}.svg`}
                      alt="Google Logo"
                      className="w-6 h-6"
                    />
                  </span>
                  <span className="block flex-grow -translate-x-5">
                    {safeProvider.name}
                  </span>
                </SecondaryButton>
              )
            })}
        </div>
      </div>
    </SignInModalPortal>
  )
}
