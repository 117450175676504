import { useGlobalContext } from "@/hooks/useGlobalContext"
import { ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

const ModalPortal = ({ children }: { children: ReactNode }) => {
  const { state } = useGlobalContext()
  const ref = useRef<Element | null>(null)
  useEffect(() => {
    ref.current = document.getElementById("modal-portal")
  }, [])
  return state.modalIsOpen && ref.current
    ? createPortal(children, ref.current)
    : null
}

export default ModalPortal
