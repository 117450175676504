"use client"
import { useModal } from "@/hooks/useModal"
import { useMemo } from "react"
import ModalPortal from "./ModalPortal"
import { Text } from "./Text"
import { useGlobalContext } from "@/hooks/useGlobalContext"

export const ConfirmationModal = () => {
  const { closeModal } = useModal()
  const {
    state: { modalOptions, modalIsOpen },
  } = useGlobalContext()
  const { title, description, confirmText, cancelText, onConfirm } =
    modalOptions

  useMemo(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (target.id === "innerModal") {
        closeModal()
      }
    }
    if (modalIsOpen && modalOptions) {
      window.addEventListener("click", handleOutsideClick)
    }
    return () => {
      window.removeEventListener("click", handleOutsideClick)
    }
  }, [modalOptions])

  return (
    <ModalPortal>
      <div
        id="innerModal"
        className="fixed top-0 left-0 w-full h-full min-h-screen bg-black bg-opacity-80 flex items-center justify-center px-4 md:px-0 z-[101]"
      >
        <div className="w-full flex flex-col items-center justify-center max-w-md bg-zinc-900 border-2 border-zinc-800 rounded-xl p-4">
          <h2 className="text-2xl font-bold text-white mb-1">{title}</h2>
          <Text className="mb-4 text-center">{description}</Text>
          <div className="w-full max-w-sm flex flex-col justify-center items-center gap-2">
            <button
              aria-label="Confirm"
              onClick={onConfirm}
              className="py-3 rounded-xl bg-red-500 hover:bg-red-600 border-2 border-white transition text-white w-full"
            >
              {confirmText}
            </button>
            <button
              aria-label="Cancel"
              onClick={closeModal}
              className="py-3 rounded-xl transition text-zinc-400 hover:text-white w-full"
            >
              {cancelText}
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
