"use client"
import { ReactNode } from "react"
import { ClientSafeProvider, SessionProvider } from "next-auth/react"
import { GlobalProvider } from "@/state/GlobalContext"
import Layout from "@/components/Layout"
import { ConfirmationModal } from "@/components/ConfirmationModal"
import { SignInModal } from "@/components/SignInModal"

export const ContextWrapper = ({
  children,
  providers,
}: {
  children: ReactNode
  providers: ClientSafeProvider
}) => {
  return (
    <SessionProvider>
      <GlobalProvider>
        <div className="w-full h-full min-h-screen flex flex-col items-center overflow-x-hidden">
          <Layout>{children}</Layout>

          <SignInModal providers={providers} />
          <ConfirmationModal />
        </div>
      </GlobalProvider>
    </SessionProvider>
  )
}
