import { signIn } from "next-auth/react"
import { useGlobalContext } from "./useGlobalContext"
import { ConfirmationModalProps } from "@/types"

export function useModal() {
  const { state, dispatch } = useGlobalContext()
  const { modalIsOpen, modalOptions, signInModalIsOpen } = state

  const closeModal = () => {
    dispatch({
      type: "UPDATE_MODAL_OPTIONS",
      payload: {
        title: "",
        description: "",
        confirmText: "",
        cancelText: "",
        onConfirm: () => {},
      },
    })
    dispatch({ type: "TOGGLE_MODAL", payload: false })
  }

  const openModal = ({
    title,
    description,
    confirmText,
    cancelText,
    onConfirm,
  }: ConfirmationModalProps) => {
    dispatch({
      type: "UPDATE_MODAL_OPTIONS",
      payload: {
        title,
        description,
        confirmText,
        cancelText,
        onConfirm: () => {
          onConfirm()
          closeModal()
        },
      },
    })

    dispatch({ type: "TOGGLE_MODAL", payload: true })
    return
  }

  const closeSignInModal = () => {
    dispatch({
      type: "TOGGLE_SIGN_IN_MODAL",
      payload: false,
    })
  }

  const openSignInModal = () => {
    dispatch({
      type: "TOGGLE_SIGN_IN_MODAL",
      payload: true,
    })

    return
  }

  return {
    modalOptions,
    modalIsOpen,
    signInModalIsOpen,
    openModal,
    closeModal,
    closeSignInModal,
    openSignInModal,
  }
}
