"use client"
import { BuiltInProviderType } from "next-auth/providers"
import { ClientSafeProvider, LiteralUnion, signIn } from "next-auth/react"
import { useState } from "react"
import "@/styles/header.css"
import { useModal } from "@/hooks/useModal"

type ButtonProps = {
  children: React.ReactNode
  handleClick?: () => void
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  title?: string
  isSignIn?: boolean
  providerId?: string
  label?: string
}

type SignInButtonProps = {
  className?: string
  isPrimary?: boolean
  callback?: string
  buttonText?: string
  isGreen?: boolean
  isNav?: boolean
  providers: Record<
    LiteralUnion<BuiltInProviderType, string>,
    ClientSafeProvider
  > | null
}

export const SignInButton = ({
  providers,
  className,
  isPrimary,
  callback,
  buttonText,
  isGreen,
  isNav,
}: SignInButtonProps) => {
  const { openSignInModal } = useModal()

  return (
    <>
      {isPrimary ? (
        <button
          aria-label="Sign in to Lawn Care Quote Pro"
          title="Sign in to Lawn Care Quote Pro"
          className={`flex items-center justify-center gap-2 ${
            isNav ? "p-2" : "p-4"
          } ${
            isGreen
              ? "bg-spring-green-600 hover:bg-spring-green-700"
              : "bg-pink-600 hover:bg-pink-700"
          } transition text-white text-lg font-bold rounded-2xl border-2 border-black shadow-[4px_4px_0_0_#000] active:shadow-none active:translate-y-[4px] active:translate-x-[4px]  disabled:bg-zinc-700 group`}
          onClick={() => openSignInModal()}
        >
          Start Quoting Today
          {!isNav && (
            <span className="block translate-x-0 group-hover:translate-x-[5px] transition">
              👉
            </span>
          )}
        </button>
      ) : (
        <button
          aria-label="Sign in to Lawn Care Quote Pro"
          title="Sign in to Lawn Care Quote Pro"
          className={`flex items-center justify-center gap-2 ${
            isNav ? "p-2" : "p-4"
          } ${
            isGreen
              ? "bg-spring-green-600 hover:bg-spring-green-700"
              : "bg-pink-600 hover:bg-pink-700"
          } transition text-white text-lg font-bold rounded-2xl border-2 border-black shadow-[4px_4px_0_0_#000] active:shadow-none active:translate-y-[4px] active:translate-x-[4px]  disabled:bg-zinc-700 group`}
          onClick={() => openSignInModal()}
        >
          {/* right arrow svg icon */}
          <span className="z-10 flex items-center justify-center gap-2">
            <span className="block">{buttonText} </span>
            {!isNav && (
              <span className="block translate-x-0 group-hover:translate-x-[5px] transition">
                👉
              </span>
            )}
          </span>
        </button>
      )}
    </>
  )
}

export const PrimaryButton = ({
  children,
  handleClick,
  className,
  type,
  disabled,
  title,
  label,
}: ButtonProps) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      title={title || ""}
      aria-label={label || ""}
      type={type || "button"}
      className={`p-4 bg-spring-green-600 hover:bg-spring-green-700 transition text-white text-lg font-bold rounded-2xl border-2 border-black shadow-[4px_4px_0_0_#000] active:shadow-none active:translate-y-[4px] active:translate-x-[4px]  disabled:bg-zinc-700 ${
        className ? className : ""
      }`}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = ({
  children,
  handleClick,
  className,
  type,
  disabled,
  isSignIn,
  providerId,
  label,
}: ButtonProps) => {
  return (
    <button
      onClick={isSignIn ? () => signIn(providerId) : handleClick}
      type={type || "button"}
      aria-label={label || ""}
      disabled={disabled}
      className={`p-2 bg-zinc-600 hover:bg-zinc-800 transition shadow-md hover:shadow-sm text-white text-lg font-bold rounded-2xl border-2 border-black ${className}`}
    >
      {children}
    </button>
  )
}
