import { useGlobalContext } from "@/hooks/useGlobalContext"
import { ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

const SignInModalPortal = ({ children }: { children: ReactNode }) => {
  const { state } = useGlobalContext()
  const ref = useRef<Element | null>(null)
  useEffect(() => {
    ref.current = document.getElementById("sign-in-modal-portal")
  }, [])
  return state.signInModalIsOpen && ref.current
    ? createPortal(children, ref.current)
    : null
}

export default SignInModalPortal
