"use client"
import { usePathname } from "next/navigation"
import { ReactNode } from "react"
import { Toaster } from "react-hot-toast"

const Layout = ({ children }: { children: ReactNode }) => {
  const path = usePathname()

  return (
    <div className="w-full h-full flex flex-col items-center justify-center relative">
      <main className={`w-full h-full md:px-0 relative`}>{children}</main>
      <Toaster
        toastOptions={{
          style: {
            background: "#3f3f46",
            color: "#e4e4e7",
            border: "2px solid #a1a1aa",
          },
        }}
      />
    </div>
  )
}

export default Layout
