import { ConfirmationModalProps } from "@/types"
import { ReactNode, createContext, useReducer } from "react"

type State = {
  modalIsOpen: boolean
  modalOptions: ConfirmationModalProps
  signInModalIsOpen: boolean
}

type Action = {
  type: string
  payload: boolean | ConfirmationModalProps | null
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "TOGGLE_MODAL":
      return {
        ...state,
        modalIsOpen: action.payload as boolean,
      }
    case "UPDATE_MODAL_OPTIONS":
      return {
        ...state,
        modalOptions: action.payload as ConfirmationModalProps,
      }
    case "TOGGLE_SIGN_IN_MODAL":
      return {
        ...state,
        signInModalIsOpen: action.payload as boolean,
      }
    default:
      return state
  }
}

const initialState: State = {
  modalIsOpen: false,
  modalOptions: {
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => null,
  },
  signInModalIsOpen: false,
}

export const GlobalContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}
